import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Payload,
  UnitDetails as UnitDetailsInterface,
  UnitInterface,
} from '@orascom/api-interfaces';

export function useSaveUnit(
  unit: UnitDetailsInterface | UnitInterface,
  addSavedUnit?: (unitId: number) => Promise<Payload<void>>,
  deleteSavedUnit?: (unitId: number) => Promise<Payload<void>>
) {
  const [isSaved, setIsSaved] = useState(unit.is_user_saved);
  const [isSaving, setIsSaving] = useState(false);

  const saveUnit = () => {
    setIsSaving(true);
    if (isSaved) {
      deleteSavedUnit?.(unit.id)
        .then((res) => {
          setIsSaved(false);
          toast.success(res.message);
        })
        .catch((err) => console.warn(err))
        .finally(() => setIsSaving(false));
    } else {
      addSavedUnit?.(unit.id)
        .then((res) => {
          setIsSaved(true);
          toast.success(res.message);
        })
        .catch((err) => console.warn(err))
        .finally(() => setIsSaving(false));
    }
  };

  return { saveUnit, isSaving, isSaved, setIsSaved };
}
