import { Common } from './common.interface';
import { CountryInfo } from './country.interface';
import { UnitType } from './unit.interface';

export interface DestinationProject {
  id: number;
  name: string;
  image: string;
  delivery_date: number;
  brochure: string;
  min_bedrooms: number;
  max_bedrooms: number;
  slug: string;
  unit_types: [
    {
      name: string;
    },
    {
      name: string;
    },
    {
      name: string;
    }
  ];
  short_description: string;
}

export interface Location {
  id: number;
  time: number;
  unit: string;
  name: string;
}

export interface Destination extends Partial<CountryInfo> {
  id: number;
  slug: string;
  name: string;
  logo: string;
  video: string;
  video_playlist: string;
  broker_logo: string;
  testimonial_title: string;
  cover_image: string;
  location_title: string;
  location_description: string;
  location_image: string;
  locations: Location[];
  masterplan_title: string;
  masterplan_sub_title: string;
  masterplan_description: string;
  masterplan_image: string;
  masterplan_brochure: string;
  projects_title: string;
  projects_sub_title: string;
  unit_types: UnitType[];
  facilities_title: string;
  facilities_sub_title: string;
  facilities_image: string;
  section: { id: number; title: string; tags: [{ id: number; name: string }] };
  section_title: string;
  gallery_title: string;
  gallery: string[];
  construction_updates_title: string;
  country: {
    id: number;
    name: string;
    slug: string;
  };
  brochure: string;
  show_resale: boolean;
  units_sold: number;
  time_range: string;
  active_promotion: {
    id: number;
    image: string;
    name: string;
    description: string;
  };
  sales_kit: string;
}

export interface DestinationFacilities {
  id: number;
  title: string;
  description: string;
  icon: string;
  destination_brochure: string;
  country: { id: number; slug: string; name: string };
}

export interface DestinationEducation extends Common {
  title: string;
  description: string;
  icon: string;
}

export interface DestinationFAQ extends Common {
  order: number;
  question: string;
  answer: string;
}

export enum DESTINATION_SLUG {
  LUSTICA_BAY = 'lustica-bay',
  HAWANA_SALALAH = 'hawana-salalah',
  JABAL_SIFAH = 'jebel-sifah',
  MAKADI_HEIGHTS = 'makadi-heights',
  GOUNA = 'el-gouna',
  OWEST = 'o-west',
  BYOUM = 'byoum',
}
