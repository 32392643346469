import { Link } from 'react-router-dom';
import styles from './button.module.scss';
import Spinner, { SpinnerProps } from '../spinner/spinner';

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  secondary?: boolean;
  asLink?: boolean;
  to?: string;
  openInNewTab?: boolean;
}

export interface AnchorProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: React.ReactNode;
}

export function NavyButton(props: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={props.to ?? '/'}
        className={`btn ${props.secondary ? 'btn--secondary' : 'btn--navy '} ${
          props.className
        }`}
      >
        {props.children}
        <div className="white" />
      </Link>
    );
  }

  return (
    <button
      {...props}
      className={`btn ${props.secondary ? 'btn--secondary' : 'btn--navy '} ${
        props.className
      }`}
    >
      {props.children}
      <div className="white" />
    </button>
  );
}

export function NavyAnchor(props: Readonly<AnchorProps>) {
  return (
    <a {...props} className={`btn btn--navy ${props.className}`}>
      {props.children}
      <div className="white" />
    </a>
  );
}
export function GoldAnchor(props: Readonly<AnchorProps>) {
  return (
    <a {...props} className={`btn btn--gold ${props.className}`}>
      {props.children}
      <div className="gold" />
    </a>
  );
}

export function GoldButton(props: Readonly<ButtonProps>) {
  if (props.asLink) {
    return (
      <Link
        to={props.to ?? '/'}
        className={`btn ${
          props.secondary ? 'btn--secondary-gold' : 'btn--gold'
        }`}
        target={props.openInNewTab ? '_blank' : '_self'}
      >
        {props.children}
        <div className="gold" />
      </Link>
    );
  }
  return (
    <button
      {...props}
      className={`btn ${props.secondary ? 'btn--secondary-gold' : 'btn--gold'}`}
    >
      {props.children}
      <div className="gold" />
    </button>
  );
}

export interface LoadingButtonProps extends React.ComponentProps<'button'> {
  loading?: boolean;
  loadingText?: string;
  spinnerSize?: SpinnerProps['size'];
  hideSpinner?: boolean;
}

export function LoadingButton(props: Readonly<LoadingButtonProps>) {
  const {
    spinnerSize,
    loading,
    loadingText,
    hideSpinner,
    children,
    disabled,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      className={`${styles['loading-button']} ${
        loading ? styles['loading'] : ''
      } ${props.className ?? ''}`}
      disabled={loading || disabled}
      aria-label={loading ? 'Loading...' : undefined}
    >
      <span className={`${styles['content']}`}>{props.children}</span>
      <span className={`${styles['spinner']} loading-text`}>
        {loadingText} {!hideSpinner && <Spinner size={spinnerSize} />}
      </span>
    </button>
  );
}
