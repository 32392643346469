import { Payload, UnitInterface } from '@orascom/api-interfaces';
import styles from './units-listing.module.scss';
import SelectIcon from '../../assets/icons/left-arrow.svg?react';
import PropertyCard from '../property-card/property-card';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export interface UnitsListingProps {
  portal: 'broker' | 'sales-man';
  id: string;
  units: UnitInterface[];
  handleRemove?: (id: number) => void;
  listingView?: 'list' | 'grid';
  unitDetailsPath?: Function;
  addSavedUnit: (unitId: number) => Promise<Payload<void>>;
  deleteSavedUnit: (unitId: number) => Promise<Payload<void>>;
  hideCompare?: boolean;
  selectUnit?: boolean;
  setSelectedUnit?: Dispatch<SetStateAction<UnitInterface | undefined>>;
}

export function UnitsListing(props: Readonly<UnitsListingProps>) {
  const { units } = props;
  const { t } = useTranslation();

  return (
    <div className={styles['wrapper']}>
      <div
        className={`${styles['cards']} ${
          props.listingView &&
          props.listingView === 'list' &&
          styles['cards--list-view']
        }`}
        id={props.id}
      >
        {units.length ? (
          units.map((unit) => (
            <div className={styles['property-card-wrapper']} key={unit.id}>
              <PropertyCard
                unit={unit}
                portal={props.portal}
                handleRemove={props.handleRemove ?? undefined}
                listingView={props.listingView}
                unitDetailsPath={props.unitDetailsPath}
                addSavedUnit={props.addSavedUnit}
                deleteSavedUnit={props.deleteSavedUnit}
                hideCompare={props.hideCompare}
                selectUnit={props.selectUnit}
                setSelectedUnit={props.setSelectedUnit}
              />
            </div>
          ))
        ) : (
          <p>{t('noUnitsToDisplay')}</p>
        )}
      </div>
    </div>
  );
}

export default UnitsListing;
