import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Destination as DestinationUtilities } from '../../utils/destination.utils';
import {
  Destination as DestinationInterface,
  DestinationFacilities as DestinationFacilitiesInterface,
  DestinationEducation as DestinationEducationInterface,
  DestinationProject as DestinationProjectInterface,
  DestinationFAQ as DestinationFAQInterface,
  Location,
} from '@orascom/api-interfaces';
import { Loader } from '@orascom/common-components';
import { DestinationDetailsUI } from '@orascom/broker-sales-man-common-components';
import { ROUTES } from '../../api/routes';
import { CurrencyContext } from '@orascom/utils';
import { useTranslation } from 'react-i18next';

export function DestinationDetails() {
  const { t } = useTranslation();
  const [destinationInfo, setDestinationInfo] =
    useState<DestinationInterface>();
  const [destinationLocations, setDestinationLocations] = useState<Location[]>(
    []
  );
  const [destinationFacilities, setDestinationFacilities] = useState<
    DestinationFacilitiesInterface[]
  >([]);
  const [destinationEducation, setDestinationEducation] = useState<
    DestinationEducationInterface[]
  >([]);
  const [destinationProjects, setDestinationProjects] = useState<
    DestinationProjectInterface[]
  >([]);
  const [destinationFAQs, setDestinationFAQs] = useState<
    DestinationFAQInterface[]
  >([]);
  const [loading, setLoading] = useState(false);

  const { destinationSlug } = useParams();

  destinationFAQs.sort((a, b) => a.order - b.order);

  const {
    isLoading: isLoadingCurrency,
    setDisableCurrencySetting,
    setActiveCurrency,
  } = useContext(CurrencyContext);

  useEffect(() => {
    if (destinationSlug) {
      if (destinationSlug === 'el-gouna') {
        setActiveCurrency('USD');
        setDisableCurrencySetting({
          disabled: true,
          info: t(`elGounaCurrencyDisclaimer`),
        });
      }
    }
    return () =>
      setDisableCurrencySetting({
        disabled: false,
        info: '',
      });
  }, [destinationSlug]);

  useEffect(() => {
    if (!destinationSlug) {
      return;
    }
    setLoading(true);
    DestinationUtilities.getDestinationInfo(destinationSlug)
      .then((res) => {
        setDestinationInfo(res);

        DestinationUtilities.getDestinationLocations(res.slug)
          .then((response) => setDestinationLocations(response))
          .catch((err) => console.error(err));

        DestinationUtilities.getDestinationFacilities(res.slug)
          .then((response) => setDestinationFacilities(response))
          .catch((err) => console.error(err));

        DestinationUtilities.getDestinationEducation(res.slug)
          .then((response) => setDestinationEducation(response))
          .catch((err) => console.error(err));

        DestinationUtilities.getDestinationProjects(res.slug)
          .then((response) => setDestinationProjects(response))
          .catch((err) => console.error(err));

        DestinationUtilities.getDestinationFAQ(res.slug)
          .then((response) => {
            setDestinationFAQs(response.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [destinationSlug]);

  if (loading || !destinationInfo || isLoadingCurrency) {
    return <Loader />;
  }

  return (
    <DestinationDetailsUI
      portal="broker"
      destinationInfo={destinationInfo}
      destinationLocations={destinationLocations}
      loading={loading}
      destinationFacilities={destinationFacilities}
      destinationProjects={destinationProjects}
      destinationFAQs={destinationFAQs}
      destinationEducation={destinationEducation}
      primaryUnitsPath={ROUTES['PrimaryUnits'].path}
    />
  );
}

export default DestinationDetails;
