import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SelectDropdown,
  RangeSlider,
  Loader,
} from '@orascom/common-components';
import {
  OptionValue,
  UnitPaymentTerms,
  InstallmentFrequencyMapper,
  CountriesEnum,
  UnitCompareInterface,
} from '@orascom/api-interfaces';
import styles from './unit-payment-calculator.module.scss';
import {
  downloadSalesOffer,
  extractUniqueDurations,
  handleSharePdf,
  usePaymentPlanCalculator,
} from '@orascom/utils';
import { useParams } from 'react-router-dom';
import Numeral from 'numeral';

interface UnitPaymentCalculatorProps {
  getUnitPaymentTerms: (unitId: number) => Promise<UnitPaymentTerms[]>;
  saveButton: React.ReactNode;
  hideDetails?: boolean;
  comparePage?: boolean;
  unitCountry: CountriesEnum | undefined;
  unitId?: number;
  unitDetails: UnitCompareInterface;
  downloadOmanSalesOffer?(unitId: number, unitName: string): Promise<any>;
  salesOffer?: boolean;
}
export function UnitPaymentCalculator(
  props: Readonly<UnitPaymentCalculatorProps>
) {
  const [loading, setLoading] = useState(true);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [error, setError] = useState(false);
  const unitId =
    useParams<{ unitId: string }>().unitId ?? props.unitId?.toString();
  const {
    onSelectDownPayment,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
    showInstallmentAmount,
    hideUnitInstallments,
  } = usePaymentPlanCalculator();

  const { t } = useTranslation();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [downloadedPdf, setDownloadedPdf] = useState<File | null>(null);

  async function handleDownloadSales() {
    setLoadingPDF(true);
    try {
      if (props.unitDetails && selectedPaymentTerms) {
        const pdfData = await downloadSalesOffer(
          props.unitDetails,
          selectedPaymentTerms
        );
        if (!pdfData) {
          throw new Error('Failed to download PDF.');
        }

        const file = new File(
          [pdfData],
          `Unit_${props.unitDetails.name}_sales_offer.pdf`,
          {
            type: 'application/pdf',
          }
        );
        setDownloadedPdf(file);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingPDF(false);
    }
  }
  const handleDownloadOmanSalesOffer = async () => {
    setLoadingPDF(true);
    if (props.unitDetails) {
      try {
        const pdfBlob = await props.downloadOmanSalesOffer?.(
          props.unitDetails.id,
          props.unitDetails.name
        );

        if (!pdfBlob) {
          throw new Error('Failed to download PDF.');
        }

        const file = new File(
          [pdfBlob],
          `Unit_${props.unitDetails.name}_sales_offer.pdf`,
          {
            type: 'application/pdf',
          }
        );
        setDownloadedPdf(file);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingPDF(false);
      }
    }
  };

  useEffect(() => {
    if (!unitId) {
      return;
    }
    props
      .getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);

        setSelectedPaymentTerms(terms[0]);
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let downPaymentOptions: OptionValue[] = [];

  const selectedDownPayment: {
    value: number;
    label: string;
  } | null = selectedPaymentTerms
    ? {
        label: `${selectedPaymentTerms.downpayment_percent}%`,
        value: selectedPaymentTerms.downpayment_percent,
      }
    : null;

  if (paymentTerms?.length > 0) {
    downPaymentOptions = paymentTerms.reduce((options: OptionValue[], term) => {
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${term.downpayment_percent}%`,
          value: term.downpayment_percent,
        });
      }
      return options;
    }, []);
  }

  const installmentDurations = extractUniqueDurations(paymentTerms);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <h2 className="orascom__title">No payment plan found for this unit</h2>
    );
  }

  const displayedPaymentDetails = { ...selectedPaymentTerms };

  const comparePageCalculatorItems =
    props.unitCountry === CountriesEnum.OMAN ? (
      <div className={styles['details-item']}>
        {showInstallmentAmount(displayedPaymentDetails) && (
          <div>
            <label>
              {t('installments')} .{' '}
              <small>
                {displayedPaymentDetails.total_number_of_installments}{' '}
                {t('installments')}
              </small>
            </label>
            <p>
              {`${Numeral(displayedPaymentDetails?.installment_amount).format(
                '0,0'
              )} ${displayedPaymentDetails?.currency} `}
              <small>
                /{' '}
                {
                  InstallmentFrequencyMapper[
                    displayedPaymentDetails?.installment_frequency as number
                  ]
                }
              </small>
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className={styles['installments']}>
        {hideUnitInstallments(displayedPaymentDetails, props.unitCountry) || (
          <>
            <div>
              <label>
                {t('beforeDelivery')} .{' '}
                <small>
                  {
                    displayedPaymentDetails.total_number_of_installments_before_delivery
                  }{' '}
                  {t('installments')}
                </small>
              </label>
              <p>
                {`${Numeral(
                  displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
                ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      displayedPaymentDetails?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>

            <div>
              <label>
                {t('afterDelivery')} .{' '}
                <small>
                  {
                    displayedPaymentDetails.total_number_of_installments_after_delivery
                  }{' '}
                  {t('installments')}
                </small>
              </label>
              <p>
                {Numeral(
                  displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
                ).format('0,0')}{' '}
                {displayedPaymentDetails?.currency}{' '}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      displayedPaymentDetails?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>
          </>
        )}
      </div>
    );

  const showSalesOfferBtn = props.salesOffer &&
    props.unitDetails?.project.destination.country.slug ===
      CountriesEnum.OMAN ||
    (props.unitDetails?.project.sales_offer && selectedPaymentTerms);

  return (
    <div className="plan">
      <div className={styles['dropdowns']}>
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <label>
            {t('downpayment')} <small>{t('downpaymentDisclaimer')}</small>
          </label>
          <div className={styles['select-wrapper']}>
            <SelectDropdown
              options={downPaymentOptions}
              placeholder={t('downPayment')}
              onChange={(val) => {
                onSelectDownPayment(val?.value as number, paymentTerms);
              }}
              selectedOption={selectedDownPayment}
            />
            {selectedPaymentTerms && (
              <span className={styles['select-value']}>
                {Numeral(displayedPaymentDetails?.downpayment_amount).format(
                  '0,0'
                )}{' '}
                {displayedPaymentDetails?.currency}
              </span>
            )}
          </div>
        </div>
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <div className={styles['flex']}>
            <label>{t('installmentDuration')}</label>
            <h5>
              {displayedPaymentDetails?.installment_durationin_years}{' '}
              {t('years')}
            </h5>
          </div>
          <RangeSlider
            className={styles['range']}
            sliderValues={installmentDurations}
            inputValue={selectedPaymentTerms?.installment_durationin_years}
            onChange={(val) => {
              onSelectDuration(val, paymentTerms);
            }}
            sliderWrapperProps={{ className: styles['range-wrapper'] }}
          />
        </div>
        {props.comparePage ? comparePageCalculatorItems : null}
        {showSalesOfferBtn && (
          <div className={`${styles['pdf-buttons']}`}>
            {' '}
            <button
              id="download-btn"
              className={`${styles['download-btn']}`}
              onClick={() => {
                if (
                  props.unitDetails?.project.destination.country.slug ===
                  CountriesEnum.OMAN
                ) {
                  handleDownloadOmanSalesOffer();
                } else {
                  handleDownloadSales();
                }
              }}
            >
              {loadingPDF ? <Loader /> : t('downloadSalesOffer')}
            </button>
            <div
              className={`${
                !downloadedPdf && styles['navigation-link--disabled']
              } `}
            >
              <button
                className={`${styles['download-btn']} ${
                  !downloadedPdf ? styles['disabled'] : ''
                }`}
                onClick={() => handleSharePdf(props.unitDetails, downloadedPdf)}
                disabled={!downloadedPdf}
              >
                {t('shareSalesOffer')}
              </button>

              {!downloadedPdf ? (
                <span className="info-msg">
                  <p>{t('generationErrorMessage')}</p>
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UnitPaymentCalculator;
